
import Burger from "@/components/menu/Burger.vue";
import Sidebar from "@/components/menu/Sidebar.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Menu",
  components: {
    Burger,
    Sidebar,
  },
  setup() {
    return {
      isPanelOpen: ref(false),
    };
  },
  methods: {
    togglingSidebar(isOpen: boolean) {
      this.isPanelOpen = isOpen;
    },
  },
  watch: {
    $route() {
      this.isPanelOpen = false;
    },
  },
});
