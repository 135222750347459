<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: "Garnett", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-size: 1rem;
  letter-spacing: -0.23px;
}
</style>
