import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1416124"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_l_tile_layer = _resolveComponent("l-tile-layer")!
  const _component_l_geo_json = _resolveComponent("l-geo-json")!
  const _component_MapPopup = _resolveComponent("MapPopup")!
  const _component_l_marker = _resolveComponent("l-marker")!
  const _component_l_map = _resolveComponent("l-map")!
  const _component_bounce_loader = _resolveComponent("bounce-loader")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["map-page", { '-dark': _ctx.isDarkMode, '-loading': _ctx.isLoading }])
  }, [
    _createVNode(_component_l_map, {
      ref: "mapRef",
      class: "l-map",
      zoom: _ctx.zoom,
      center: _ctx.center
    }, {
      default: _withCtx(() => [
        _createVNode(_component_l_tile_layer, {
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          "layer-type": "base",
          name: "OpenStreetMap",
          "max-zoom": 15
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.geojsons, (geojson) => {
          return (_openBlock(), _createBlock(_component_l_geo_json, {
            key: geojson.id,
            geojson: geojson.data
          }, null, 8, ["geojson"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relevantAds, (ad) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: ad._id
          }, [
            (_ctx.getPosAd(ad))
              ? (_openBlock(), _createBlock(_component_l_marker, {
                  key: 0,
                  "lat-lng": _ctx.getPosAd(ad),
                  draggable: false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MapPopup, { ad: ad }, null, 8, ["ad"])
                  ]),
                  _: 2
                }, 1032, ["lat-lng"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _: 1
    }, 8, ["zoom", "center"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_bounce_loader, {
        class: "spinner",
        loading: _ctx.isLoading,
        color: "#fdcd56",
        size: '100px'
      }, null, 8, ["loading"])
    ])
  ], 2))
}