
import { defineComponent } from "vue";
import Burger from "@/components/menu/Burger.vue";

export default defineComponent({
  name: "Sidebar",
  props: {
    isPanelOpen: {
      type: Boolean,
    },
  },
  components: {
    Burger,
  },
  methods: {
    closeSidebarPanel() {
      this.$emit("toggleClosing", false);
    },
  },
});
