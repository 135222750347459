
import { RelevantAdActionTypes } from "@/store/modules/relevantAd/action-types";
import Card from "@/components/Card.vue";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  Ref,
  ref,
} from "vue";
import BounceLoader from "vue-spinner/src/BounceLoader.vue";
import { useStore } from "vuex";

const namespace = "relevantAd";

export default defineComponent({
  name: "ListPage",
  components: {
    BounceLoader,
    Card,
  },
  setup() {
    const store = useStore();
    const listPageRef: Ref<HTMLDivElement | null> = ref(null);

    const page = computed(() => store.getters[`${namespace}/getCurrentPage`]);
    const totalPages = computed(
      () => store.getters[`${namespace}/getTotalPages`]
    );

    const isLoading = computed(
      () => store.getters[`${namespace}/getIsLoading`]
    );

    const isDarkMode = computed(
      () => store.getters[`${namespace}/getIsDarkMode`]
    );

    const relevantAds = computed(
      () => store.getters[`${namespace}/getRelevantAds`]
    );

    const handleScroll = () => {
      if (
        !isLoading.value &&
        page.value + 1 < totalPages.value &&
        window.scrollY + window.outerHeight >=
          (listPageRef.value as HTMLDivElement).clientHeight
      ) {
        store.dispatch(`${namespace}/${RelevantAdActionTypes.SetNewPage}`, {
          page: page.value + 1,
        });
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      store,
      page,
      relevantAds,
      isLoading,
      totalPages,
      isDarkMode,
      listPageRef,
    };
  },
});
