
import { computed, defineComponent, onMounted, watchEffect } from "vue";
import Menu from "@/components/menu/Menu.vue";
import Dropfilters from "@/components/Dropfilters.vue";
import { initialFilters } from "@/store/modules/relevantAd/state";
import { RelevantAdActionTypes } from "@/store/modules/relevantAd/action-types";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Toggle from "@vueform/toggle";
import router from "@/router";

const namespace = "relevantAd";

import "@vueform/toggle/themes/default.css";

export default defineComponent({
  name: "Home",
  components: {
    Menu,
    Dropfilters,
    Toggle,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isDarkMode = computed(
      () => store.getters[`relevantAd/getIsDarkMode`]
    );

    const isMapMode = computed(() => store.getters[`relevantAd/getIsMapMode`]);

    const filtersOptions = computed(
      () => store.getters[`${namespace}/getCurrentFilters`]
    );

    const filtersCount = computed(
      () => store.getters[`${namespace}/getFiltersCount`]
    );

    watchEffect(
      () => {
        const opt = { ...filtersOptions.value };

        const isDark = !opt.isLegal;
        delete opt.isLegal;

        if (isDark) {
          router.push({
            name: isMapMode.value ? "DarkMap" : "DarkList",
            query: opt,
          });
        } else {
          router.push({
            name: isMapMode.value ? "HomeMap" : "HomeList",
            query: opt,
          });
        }
      },
      {
        flush: "post",
      }
    );

    onMounted(() => {
      store.dispatch(`${namespace}/${RelevantAdActionTypes.FetchingCities}`);

      if (Object.keys(route.query).length > 0) {
        store.dispatch(
          `${namespace}/${RelevantAdActionTypes.SetDefaultFilter}`,
          route.query
        );
      }

      if (route.name?.toString().includes("Dark")) {
        store.dispatch(`${namespace}/${RelevantAdActionTypes.SetDarkMode}`);
      } else {
        store.dispatch(`${namespace}/${RelevantAdActionTypes.SetLegalMode}`);
      }

      if (route.name === "HomeMap" || route.name === "DarkMap") {
        store.dispatch(
          `${namespace}/${RelevantAdActionTypes.SetMapMode}`,
          true
        );
      }
    });

    const handleDarkToggle = (event: boolean) => {
      if (event) {
        store.dispatch(`${namespace}/${RelevantAdActionTypes.SetDarkMode}`);
      } else {
        store.dispatch(`${namespace}/${RelevantAdActionTypes.SetLegalMode}`);
      }
    };

    const handleMapToggle = (event: boolean) => {
      store.dispatch(`${namespace}/${RelevantAdActionTypes.SetMapMode}`, event);
    };

    const changeFilters = (
      filtersOptions: {
        surfaceValue: number[];
        roomValue: number[];
        priceValue: number[];
        exceedingValue: number[];
        furnishedValue: string;
        cityValue: string;
        isHouseValue: string;
        districtValues: never[];
      } | null
    ) => {
      if (filtersOptions) {
        store.dispatch(`${namespace}/${RelevantAdActionTypes.SetNewFilters}`, {
          filters: {
            ...filtersOptions,
            isLegal: !isDarkMode.value,
          },
        });
      } else {
        store.dispatch(`${namespace}/${RelevantAdActionTypes.SetNewFilters}`, {
          filters: initialFilters,
        });
      }
    };

    return {
      isMapMode,
      isDarkMode,
      filtersOptions,
      filtersCount,
      changeFilters,
      handleDarkToggle,
      handleMapToggle,
    };
  },
});
