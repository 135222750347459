
import { defineComponent } from "vue";

export default defineComponent({
  name: "Burger",
  props: {
    isBurgerActive: {
      type: Boolean,
    },
    isFixed: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toggle() {
      this.$emit("togglingSidebar", !this.isBurgerActive);
    },
  },
});
