
import { defineComponent } from "vue";
import { RelevantAd } from "@/store/modules/relevantAd/interfaces";

export default defineComponent({
  name: "Card",
  props: {
    ad: { type: Object as () => RelevantAd },
    isLoading: { type: Boolean },
    isFromMap: { type: Boolean, default: false },
  },
  setup() {
    const getDisplayableDate = (date?: string): string => {
      if (!date) return "";
      const d = new Date(date);

      const todayDate = new Date();
      const todayDateFormatted = todayDate.toLocaleDateString();
      const yesterdayDate = new Date();
      yesterdayDate.setDate(todayDate.getDate() - 1);
      const yesterdayDateFormatted = yesterdayDate.toLocaleDateString();
      const dayBeforeYesterdayDate = new Date();
      dayBeforeYesterdayDate.setDate(yesterdayDate.getDate() - 1);
      const dayBeforeYesterdayDateFormatted =
        yesterdayDate.toLocaleDateString();

      const dateFormat = d.toLocaleDateString();
      const timeFormat = d.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
      });

      return `Vu ${
        dateFormat === todayDateFormatted
          ? "aujourd'hui"
          : dateFormat === yesterdayDateFormatted
          ? "hier"
          : dateFormat === dayBeforeYesterdayDateFormatted
          ? "avant-hier"
          : "le " + dateFormat
      } à ${timeFormat}`;
    };

    const getUrl = (url?: string): string => {
      if (!url) return "";
      return url;
    };

    const getImgUrl = (website?: string) => {
      const images = require.context("@/assets/images", false, /\.png$/);
      try {
        return images("./" + website + "-img.png");
      } catch (error) {
        return images("./default-img.png");
      }
    };

    const redirectTo = (ad?: RelevantAd): void => {
      if (!ad) return;
      window.open(getUrl(ad.url), "_blank");
    };

    return {
      getImgUrl,
      getUrl,
      redirectTo,
      getDisplayableDate,
    };
  },
});
