import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fa351a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-card" }
const _hoisted_2 = {
  key: 0,
  class: "no-result"
}
const _hoisted_3 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!
  const _component_bounce_loader = _resolveComponent("bounce-loader")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "listPageRef",
    class: _normalizeClass(["list-page", { '-dark': _ctx.isDarkMode }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relevantAds, (ad) => {
      return (_openBlock(), _createElementBlock("div", {
        key: ad._id,
        class: "card"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Card, {
            ad: ad,
            isLoading: _ctx.isLoading
          }, null, 8, ["ad", "isLoading"])
        ])
      ]))
    }), 128)),
    (!_ctx.relevantAds.length && !_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " No result "))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_bounce_loader, {
        class: "spinner",
        loading: _ctx.isLoading,
        color: "#fdcd56",
        size: '100px'
      }, null, 8, ["loading"])
    ])
  ], 2))
}